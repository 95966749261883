import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SpeedBackground } from "../assets/shape.svg";
import offerButton from "../assets/getOfferButton.png";
import offerButtonHover from "../assets/getOfferHoverButton.png";

const Speedometer = () => {
  const [speed, setSpeed] = useState(0);
  const [transform, setTransform] = useState(`rotate(-14deg)`);
  const [firstLoad, setFirstLoad] = useState(false);
  // const speedString = String(speed).padStart(3, "0");
  // const digits = speedString.split("").map(Number);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!firstLoad) {
        const newSpeed = Math.min(speed + Math.floor(2 * 3.5), 180);
        setSpeed(newSpeed);
        setTransform(`rotate(${newSpeed + 90}deg)`);
        if (speed === 180) {
          setFirstLoad(true);
        }
      } else if (speed > 0) {
        const newSpeed = Math.max(speed - Math.floor(2 * 3.5), 0);
        setSpeed(newSpeed);
        setTransform(`rotate(${newSpeed - 14}deg)`);
      }
    }, 80);
    return () => clearInterval(interval);
  }, [speed, firstLoad]);

  const transition = `${transform} 20s cubic-bezier(0.075, 0.82, 0.165, 1), transition-delay 10s`;

  return (
    <div className="speedometerControl speedometerControl-Digital">
      <div id="speedometerWraper-myValues">
        <div className="envelope">
          <div
            className="speedNobe"
            style={{
              transition,
              transform,
            }}
          >
            <div></div>
          </div>
          <div className="speedPosition"></div>
          <div className="clock">
            <div className="led-display">
            </div>
            <SpeedBackground />
          </div>
          <Link className="link" to="/kontakta-oss">
            <img src={offerButtonHover} className="offerButtonHover" alt="" />
            <img src={offerButton} className="offerButton" alt="" />
            <p className="offerButtonText">VÄRDERA NU</p>
          </Link>
        </div>

        <div className="led-display">
             
            </div>
      </div>
    </div>
  );
};

export default Speedometer;
