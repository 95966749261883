import car from "../assets/car1.png";
import happyCustomer from "../assets/happyCustomer.png";
import deal from "../assets/deal.png";

export const benefitsItems = [
  {
    title: "Vi köper alla bilar oavsett",
    image: car,
    description:
      "Begagnade, Defekta, Trasiga, Krockade, Avställda, Obesiktigad, Och även dyra fel",
  },
  {
    title: "Få Betalt på det sätt som passar dig bäst",
    image: deal,
    description:
      " Vi erbjuder flexibilitet när det gäller betalning för din begagnade bil. Swish, Direkt Banköverföring...",
  },
  {
    title: "Få din bil såld på en minut",
    image: happyCustomer,
    description:
      "Så om du vill sälja din begagnade bil på ett smidigt och enkelt sätt, kontakta oss idag. Vi ser fram emot att hjälpa dig!",
  },
];
