import React from "react";
import {
  Container,
  Typography,
  Link,
  List,
  ListItem,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: theme.spacing(4),
  maxWidth: "800px",
  color: "#333",
  marginTop: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: "var(--color-darknavy)",
  fontWeight: "700",
  marginBottom: theme.spacing(3),
  fontSize: "2rem",
  textAlign: "left",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: "var(--color-darknavy)",
  fontWeight: "600",
  marginBottom: theme.spacing(2),
  fontSize: "1.25rem",
  textAlign: "left",
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: "var(--color-darknavy)",
  lineHeight: 1.75,
  marginBottom: theme.spacing(2),
  fontSize: "1.2rem",
  textAlign: "left",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: "var(--color-darknavy)",
  lineHeight: 1,
  paddingLeft: theme.spacing(2),
  textAlign: "left",
  fontSize: "1rem",
}));

const PrivacyPolicy = () => {
  return (
    <StyledContainer>
      <Heading variant="h4" gutterBottom>
        Integritetspolicy
      </Heading>
      <Paragraph>
        Denna integritetspolicy beskriver hur Tigerbilar.se ("vi", "oss" eller
        "vår") samlar in, använder och skyddar din personliga information när du
        använder vår webbplats och våra tjänster.
      </Paragraph>
      <SubHeading variant="h5" gutterBottom>
        Vilka vi är
      </SubHeading>
      <Paragraph>
        Tigerbilar.se är en tjänst som hjälper dig att sälja din trasiga bil. Vi
        värnar om din integritet och säkerställer att din personliga information
        hanteras på ett säkert sätt.
      </Paragraph>
      <SubHeading variant="h5" gutterBottom>
        Vilken information vi samlar in
      </SubHeading>
      <Paragraph>Vi samlar in följande information:</Paragraph>
      <List>
        <StyledListItem>
          Namn och kontaktuppgifter (t.ex. e-postadress och telefonnummer).
        </StyledListItem>
        <StyledListItem>
          Information om ditt fordon (t.ex. registreringsnummer, modell, skick).
        </StyledListItem>
        <StyledListItem>
          Teknisk data (t.ex. IP-adress, webbläsartyp och enhetsinformation).
        </StyledListItem>
      </List>
      <SubHeading variant="h5" gutterBottom>
        Hur vi använder informationen
      </SubHeading>
      <Paragraph>Vi använder din information för att:</Paragraph>
      <List>
        <StyledListItem>
          Bearbeta din förfrågan om att sälja din trasiga bil.
        </StyledListItem>
        <StyledListItem>Kommunicera med dig och ge dig support.</StyledListItem>
        <StyledListItem>
          Förbättra våra tjänster och användarupplevelse.
        </StyledListItem>
        <StyledListItem>Följa gällande lagar och regler.</StyledListItem>
      </List>
      <SubHeading variant="h5" gutterBottom>
        Hur vi delar din information
      </SubHeading>
      <Paragraph>
        Vi delar inte din personliga information med tredje part, förutom när
        det är nödvändigt för att tillhandahålla våra tjänster eller följa
        lagliga skyldigheter. Vi kan dela din information med:
      </Paragraph>
      <List>
        <StyledListItem>
          Betrodda partners som hjälper oss att driva vår verksamhet.
        </StyledListItem>
        <StyledListItem>Myndigheter om det krävs enligt lag.</StyledListItem>
      </List>
      <SubHeading variant="h5" gutterBottom>
        Cookies och spårningstekniker
      </SubHeading>
      <Paragraph>
        Vi använder cookies och liknande tekniker för att förbättra din
        upplevelse på vår webbplats. Cookies används för att analysera trafik,
        anpassa innehåll och tillhandahålla relevanta annonser. Du kan stänga av
        cookies via din webbläsares inställningar, men vissa delar av
        webbplatsen kanske inte fungerar korrekt utan dem.
      </Paragraph>
      <SubHeading variant="h5" gutterBottom>
        Hur länge vi behåller din information
      </SubHeading>
      <Paragraph>
        Vi behåller din personliga information så länge det är nödvändigt för
        att uppfylla de syften som beskrivs i denna integritetspolicy, eller så
        länge som krävs enligt lag.
      </Paragraph>
      <SubHeading variant="h5" gutterBottom>
        Dina rättigheter
      </SubHeading>
      <Paragraph>Du har rätt att:</Paragraph>
      <List>
        <StyledListItem>
          Begära tillgång till den information vi har om dig.
        </StyledListItem>
        <StyledListItem>
          Begära att vi rättar felaktig eller ofullständig information.
        </StyledListItem>
        <StyledListItem>
          Begära radering av din personliga information.
        </StyledListItem>
        <StyledListItem>
          Invända mot behandling av din information för direktmarknadsföring.
        </StyledListItem>
      </List>
      <SubHeading variant="h5" gutterBottom>
        Kontakta oss
      </SubHeading>
      <Paragraph>
        Om du har några frågor om denna integritetspolicy eller hur vi hanterar
        din information, vänligen kontakta oss på{" "}
        <Link href="mailto:info@tigerbilar.se">info@tigerbilar.se</Link>.
      </Paragraph>
    </StyledContainer>
  );
};

export default PrivacyPolicy;
