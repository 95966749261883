import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function AboutUs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Card
      sx={{
        maxWidth: 800,
        textAlign: "left",
        backgroundColor: "transparent",
        margin: "10px auto",
        boxShadow: 0,
        color: "rgb(0 0 0 / 64%)",
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ mb: 1.5 }}>
          Bilar är vår verksamhet. Vi vet hur viktiga de kan bli i människans
          liv. Dessutom vet vi också att beslutet att sälja en bil kan vara
          svårt. Det är därför vi garanterar våra kunder att vi kommer att ge
          dem ett konkurrenskraftigt erbjudande på deras fordon. Snabb, lätt och
          garanterat. Vi jobbar hårt för att du kan få bästa pris, service,
          enkel(digital), sist men inte minst med hållbar verksamhet och med bra
          miljöpåverkning. Vi grundades med målet att hjälpa till att underlätta
          tid och ansträngning för att sälja fordon, oavsett deras skick. Vi har
          sett (och köpt) allt från som nya, till begagnade, till skadade och
          till och med totalförlustfordon. Så hjälper vi dig att sälja din bil
          snabbt och enkelt med:
        </Typography>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          Rättvisa priser!
        </Typography>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          Gratis bogsering!
        </Typography>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          Jättebra service!
        </Typography>
      </CardContent>
      <CardActions sx={{ ml: "12px" }}>
        <Button
          variant="contained"
          component={Link}
          to="/kontakta-oss"

          sx={{
            borderRadius: "50px",
            color: "white",
            fontSize: "18px",
            width: "150px",
            background: "var(--color-blue)",
          }}
        >
          Sälj Nu
        </Button>
      </CardActions>
    </Card>
  );
}
