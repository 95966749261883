import * as React from "react";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { CustomGridItem, CustomTypography } from "./CustomStyles";
// img
import imgDetail from "../../assets/question.png";
import imgDetail2 from "../../assets/form.png";
import { BenefitItems } from "./data";

const DetailsSection = () => (
  <Grid
    container
    spacing={{ xs: 4, sm: 4, md: 0 }}
    sx={(theme) => ({
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
      },
    })}
  >
    <CustomGridItem item xs={18} sm={8} md={6} component="section">
      <Box
        component="article"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
          [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
        })}
      >
        <Typography
          variant="h2"
          component="h2"
          sx={(theme) => ({
            fontWeight: "700",
            textAlign: "start",
            color: "var(--color-darknavy)",
            [theme.breakpoints.down("md")]: {
              textAlign: "center",
            },
          })}
        >
          Varför Ska Du Sälja Din Trasiga Bil till Oss?
        </Typography>
        <CustomTypography sx={{ color: "var(--color-darknavy)" }}>
          Att äga en trasig bil kan vara en börda. Den tar upp plats, den kanske
          inte längre är säker att köra, och reparationskostnaderna kan vara
          orimligt höga. Men vad kan du göra med den? Svaret är enkelt – sälj
          den till oss! Vi specialiserar oss på att köpa bilar i alla skick, och
          vår process är utformad för att vara så enkel och smidig som möjligt
          för dig.
        </CustomTypography>
      </Box>
    </CustomGridItem>

    <Grid item xs={12} sm={4} md={6}>
      <img
        src={imgDetail}
        alt="Illustration of a confused person shrugging with question marks above their head, standing in a room with plants and framed pictures."
        title="Confused Person with Question Marks"
        style={{
          height: "auto",
          maxWidth: "100%",
          width:'600px'
        }}
      />
    </Grid>

    <Grid
      item
      xs={12}
      sm={4}
      md={6}
      sx={{
        order: { xs: 4, sm: 4, md: 3 },
      }}
    >
      <img
        src={imgDetail2}
        alt="Illustration of a happy person celebrating next to an orange car, with a clipboard showing an approved document, car keys, and currency symbols in the background."
        title="Car selling Approval and Celebration"
        style={{
          height: "auto",
          maxWidth: "100%",
        }}
      />
    </Grid>
    <CustomGridItem
      item
      xs={12}
      sm={8}
      md={6}
      sx={{
        order: { xs: 3, sm: 3, md: 4 },
      }}
    >
      <Box
        component="article"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
          [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
        })}
      >
        <Typography
          variant="h2"
          component="h2"
          sx={(theme) => ({
            fontWeight: "700",
            textAlign: "start",
            marginBottom: "12px",
            color: "var(--color-darknavy)",
            [theme.breakpoints.down("md")]: {
              textAlign: "center",
            },
          })}
        >
          Fördelarna med att Sälja Din Trasiga Bil till Oss
        </Typography>
        <CustomTypography sx={{ color: "var(--color-darknavy)" }}>
          Att sälja en trasig bil kan kännas som en utmaning, men det behöver
          det inte vara. Här är några anledningar till varför du bör välja oss:
          <Grid item xs={12} md={16}>
            <List>
              {BenefitItems.map((item) => (
                <ListItem
                  sx={(theme) => ({
                    [theme.breakpoints.down("md")]: {
                      padding: "0px",
                    },
                  })}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "var(--color-lightblue)" }}>
                      {item.step}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      color: "var(--color-darknavy)",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                    secondaryTypographyProps={{
                      color: "var(--color-darknavy)",
                      fontSize: "18px",
                    }}
                    primary={item.title}
                    secondary={item.Description}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </CustomTypography>
      </Box>
    </CustomGridItem>
  </Grid>
);

export default DetailsSection;
