import { Grid, styled, Typography } from "@mui/material";

export const CustomGridItem = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const CustomTypography = styled(Typography)(({ theme }) => ({
  textAlign: "start",
  lineHeight: "1.5",
  color: "var(--color-black)",
  marginTop: "1.5rem",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));
