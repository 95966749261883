import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

const ButtonRoot = styled(MuiButton)(({ theme, size }) => ({
  borderRadius: "10px",
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: 'Gelion',
  textTransform:"none",
  padding: theme.spacing(2, 4),
  fontSize: theme.typography.pxToRem(14),
  scrollBehavior: "smooth",
  boxShadow: "none",
  "&:active, &:focus": {
    boxShadow: "none",
  },
  ...(size === "small" && {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(18),
    color:'white',
    borderRadius:'50px',
    "&:hover": {
      background: 'var(--color-orange)',
    },
  }),
  ...(size === "large" && {
    padding: theme.spacing(2, 5),
    fontSize: theme.typography.pxToRem(24),
    color: "white",
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.light,
    },
  }),
}));

// See https://mui.com/guides/typescript/#usage-of-component-prop for why the types uses `C`.
function Button(props) {
  return <ButtonRoot {...props} />;
}

export default Button;
