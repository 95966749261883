import * as React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

import faqImage from "../../assets/faq.png";
import { faqData } from "./data";
import wave from "../../assets/wave.svg";
import waveTop from "../../assets/waveTop.svg";
export default function Faq() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginBottom: "-10px",
        }}
      >
        <img
          src={waveTop}
          style={{
            height: "auto",
            width: "100%",
          }}
          alt="Abstract wave pattern in a flowing design, symbolizing fluidity and movement."
          title="Fluid Wave Design"
        />
      </Box>
      <Grid
        container
        justifyContent={"space-around"}
        alignItems={"flex-start"}
        sx={(theme) => ({
          mt: 0,
          py: theme.spacing(6),
          background: "var(--color-lightblue)",
          border: "none",
          [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(20),
            paddingRight: theme.spacing(20),
          },
          [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
        })}
      >
        <Grid item>
          <img
            src={faqImage}
            alt="Illustration of a person standing and thinking, with a large question mark in the background and smaller question marks around."
            title="Person Thinking with Question Marks"
            style={{
              height: "auto",
              maxWidth: "100%",
            }}
          />
        </Grid>
        <Grid item xs={18} sm={8} md={6}>
          <List>
            <Typography
              variant="h2"
              component="h2"
              sx={(theme) => ({
                fontWeight: "700",
                textAlign: "start",
                color: "var(--color-darknavy)",
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                },
              })}
            >
              Vanliga frågor om att sälj en trasig bil
            </Typography>
            {faqData.map((item) => (
              <ListItem
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: {
                    padding: "0px",
                  },
                })}
              >
                <ListItemText
                  primaryTypographyProps={{
                    color: "var(--color-darknavy)",
                    fontWeight: "bold",
                  }}
                  secondaryTypographyProps={{
                    color: "var(--color-darknavy)",
                  }}
                  primary={item.question}
                  secondary={
                    <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Box>
        <img
          src={wave}
          style={{
            height: "auto",
            width: "100%",
          }}
          alt="Abstract wave pattern in a flowing design, symbolizing fluidity and movement."
          title="Fluid Wave Design"
        />
      </Box>
    </>
  );
}
