import React from "react";

export default function SlagenRight() {
  return (
    <div className="slagenRightWrapper">
      <p>snabbt</p>
      <p>lätt</p>
      <p>garanterat</p>
      <hr />
    </div>
  );
}
