import * as React from "react";
import Grid from "@mui/material/Grid";
import BenefitCard from "../components/BenefitCard";
import { benefitsItems } from "../data/BenefitData";

export default function Benefits() {
  return (
    <Grid
      container
      sx={(theme) => ({
        flexGrow: 1,
        spacing: 2,
        mt: 15,
        [theme.breakpoints.down("md")]: {
          marginTop: 4,
          marginBottom: 4,
        },
      })}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          {benefitsItems.map((item, index) => (
            <Grid key={index} item>
              <BenefitCard item={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
