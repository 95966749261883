import React from "react";
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { CustomGridItem, CustomTypography } from "./CustomStyles";
import processImg from "../../assets/process.png";
import { howItWorksItems } from "./data";
const HowItWorksSection =()=>
<Grid
container
spacing={{ xs: 4, sm: 4, md: 0 }}
sx={(theme) => ({
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
})}
>
<CustomGridItem item xs={18} sm={8} md={6} component="section">
  <Box
    component="article"
    sx={(theme) => ({
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    })}
  >
    <Typography
      variant="h4"
      component="h3"
      sx={(theme) => ({
        fontWeight: "700",
        textAlign: "start",
        color:'var(--color-darknavy)',
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      })}
    >
      Så Här Fungerar Det
    </Typography>
    <CustomTypography sx={{color:'var(--color-darknavy)',}}>
    Att sälja din trasiga bil till oss är enkelt. Följ dessa steg för att snabbt och smidigt bli av med din bil och få betalt:
    </CustomTypography>
    <Grid item xs={12} md={16}>
            <List>
              {howItWorksItems.map((item)=> <ListItem
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: {
                    padding: "0px",
                  },
                })}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "var(--color-lightblue)" }}>{item.step}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    color:'var(--color-darknavy)',
                    fontWeight: "bold",
                    
                  }}
                  secondaryTypographyProps={{
                    color:'var(--color-darknavy)',
                
                  }}
                  primary={item.title}
                  secondary={<p dangerouslySetInnerHTML={{ __html: item.Description }} />}
                />
              </ListItem>)}
             
              
            </List>
          </Grid>
  </Box>
</CustomGridItem>

<Grid item xs={12} sm={4} md={6}>
  <img
    src={processImg}
    alt="Illustration of a woman holding a document, standing next to three connected gears labeled 1, 2, and 3, with an arrow pointing forward."
    title="Step-by-Step Process with Connected Gears"
    style={{
      height: "auto",
      maxWidth: "100%",
    }}
  />
</Grid>



</Grid>

export default HowItWorksSection