import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function BenefitCard(item) {
  return (
    <Card
      sx={{
        maxWidth: 345,
        height: "300px",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      <CardMedia
        component="img"
        height="140"
        image={item.item.image}
        alt="blue car"
        sx={{ objectFit: "contain" }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {item.item.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.item.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
