import { Box, Button, Typography } from "@mui/material";
import { CustomTypography } from "./CustomStyles";
import theme from "../theme";

const CallOutSection = () => {
  return (
    <Box
      sx={(theme) => ({
        background: "var(--color-lightblue)",
        py: theme.spacing(6),
        [theme.breakpoints.up("md")]: {
          paddingLeft: theme.spacing(20),
          paddingRight: theme.spacing(20),
        },
        [theme.breakpoints.down("md")]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      })}
    >
      <Typography
        variant="h2"
        component="h2"
        sx={(theme) => ({
          fontWeight: "700",
          textAlign: "center",
          color: "var(--color-darknavy)",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
        })}
      >
        Vi köper trasiga bilar i alla former och skick!
      </Typography>
      <CustomTypography
        sx={{
          color: "var(--color-darknavy)",
          textAlign: "center",
          paddingBottom: theme.spacing(3),
        }}
      >
        Vårt mål är att hjälpa alla som vill sälja sin begagnade bil, oavsett
        årsmodell eller skick. Vi guidar dig genom hela processen och tar hand
        om registrering och ägarbyte. Du kan slappna av och känna dig trygg med
        att allt hanteras på rätt sätt.
      </CustomTypography>
      <Box>
        <Button
          variant="contained"
          href="#kontakta-oss"
          sx={{
            mr: 2,
            px: 4,
            py: 1,
            fontSize: "1.2rem",
            fontWeight: 700,
            textTransform: "none",
            borderColor: "#14192d",
            color: "white",
            borderRadius: "50px",
            backgroundColor: "var(--color-blue)",
            "&&:hover": {
              backgroundColor: "var(--color-orange)",
            },
          }}
        >
          Få en värdering!
        </Button>
      </Box>
    </Box>
  );
};
export default CallOutSection;
