import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import SaljTrasigBil from "./pages/SaljTrasigBil";
import withRoot from './components/withRoot'
import PrivacyPolicy from "./pages/PrivacyPolicy";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/kontakta-oss" component={ContactUs} />
        <Route path="/om-oss" component={AboutUs} />
        <Route path="/salj-trasig-bil" component={SaljTrasigBil} />
        <Route path='/integritetspolicy' component={PrivacyPolicy}/>
      </Switch>
      <Footer />
    </div>
  );
}

export default withRoot(App)