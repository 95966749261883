import React from "react";
import PhoneInTalkTwoToneIcon from "@mui/icons-material/PhoneInTalkTwoTone";
import DirectionsCarFilledTwoToneIcon from '@mui/icons-material/DirectionsCarFilledTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
export const sidebarItems = [
  // {
  //   title: "Hem",
  //   path: "/",
  //   icon: <HouseIcon style={{ fontSize: "2rem" }} />,
  //   cName: "nav-text",
  // },
  // {
  //   title: "Hur det fungerar",
  //   path: "/hur-det-fungerar",
  //   icon: <TimeToLeaveIcon style={{ fontSize: "2rem" }} />,
  //   cName: "nav-text",
  // },
  {
    title: 'Sälj trasig bil',
    path:'/salj-trasig-bil',
    icon:<DirectionsCarFilledTwoToneIcon style={{ fontSize: "2rem" }} />
  },
  {
    title: "Kontakta Oss",
    path: "/kontakta-oss",
    icon: <PhoneInTalkTwoToneIcon style={{ fontSize: "2rem" }} />,
    cName: "nav-text",
  },
  {
    title: "Om oss",
    path: "/om-oss",
    icon: < BusinessTwoToneIcon style={{ fontSize: "2rem" }}/>,
    cName: "nav-text",
  }
];
