import * as React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "../components/Paper";
export default function FormView(props) {
  const { children } = props;

  return (
   
      <Container  maxWidth="xs">
        <Box sx={{ mt: 2, mb: 8,borderRadius:"10px" }}>
          <Paper
            background="light"
            sx={{ py: { xs: 1, md: 2 }, px: { xs: 2, md: 4 }, borderRadius:"10px" }}
          >
            {children}
          </Paper>
        </Box>
      </Container>
    
  );
}

FormView.propTypes = {
  children: PropTypes.node,
};
