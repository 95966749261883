export const howItWorksItems = [
  {
    step: "1",
    title: "Begär en Offert",
    Description: `Fyll i vårt <a href="#kontakta-oss" style="color: var(--color-blue); text-decoration: none; cursor: pointer; font-weight: bold;">enkla formulär</a> med registreringsnummer och dina kontaktuppgifter.`,
  },
  {
    step: "2",
    title: "Få en gratis värdering",
    Description: `Vår team kommer att utvärdera informationen och ge dig en rättvis offert baserat på bilens skick och marknadsvärde.
       Vi strävar efter att ge dig den bästa möjliga priset för din bil.`,
  },
  {
    step: "3",
    title: "Acceptera Offerten",
    Description: `Om du är nöjd med offerten, bekräfta att du vill sälja bilen till oss.
      Vi arrangerar en tid och plats för att hämta bilen som passar dig.`,
  },
  {
    step: "4",
    title: "Hämtning och Betalning",
    Description: `Vi kommer till din plats och inspekterar bilen.
      Efter inspektionen betalar vi dig direkt, antingen via banköverföring eller kontant, beroende på vad som passar dig bäst.
      Vi tar med bilen och du är nu fri från den bördan!`,
  },
];

export const BenefitItems = [
  {
    step: "1",
    title: "Snabb och Enkel Process",
    Description:
      "Vi vet att din tid är värdefull. Därför har vi utvecklat en snabb och enkel process för att få en offert och sälja din bil på samma dag.",
  },
  {
    step: "2",
    title: "Bästa Pris Garanterat",
    Description: `Vi erbjuder konkurrenskraftiga priser för din trasiga bil. Vårt mål är att du ska få det bästa möjliga priset, oavsett bilens skick.`,
  },
  {
    step: "3",
    title: "Hämtning på Plats",
    Description: `Ingen anledning att oroa sig för hur du ska få din trasiga bil till oss. Vi hämtar den direkt från din adress utan extra kostnad.`,
  },
];

export const faqData =[
  {
    question: 'Hur snabbt kan jag förvänta mig att få betalningen?',
    answer:`En av våra främsta prioriteringar är att säkerställa att du får betalningen snabbt och säkert. När vi hämtar din bil och har genomfört inspektionen på plats, betalar vi dig omedelbart. Du kan välja mellan att få betalningen via banköverföring eller kontant, beroende på vad som passar dig bäst. Banköverföringar är ofta den mest bekväma och säkra metoden, och pengarna bör vara på ditt konto inom några timmar efter att transaktionen har slutförts. Om du föredrar kontant betalning, kan vår representant ge dig pengarna direkt på plats. Vi förstår att du vill ha tillgång till dina pengar så snabbt som möjligt, och vi gör vårt bästa för att säkerställa att det händer.`
  },
  {
    question: 'Vad behöver jag förbereda innan ni hämtar min bil?',
    answer:`Värderingen av din trasiga bil baseras på flera faktorer för att säkerställa att vi ger dig ett rättvist och konkurrenskraftigt pris. Här är några av de faktorer vi tar hänsyn till:</br>
    <ol></br>
    <li>Bilens skick: Vi tittar noga på bilens övergripande skick. Detta inkluderar mekaniska problem, krockskador, rost och andra skador som kan påverka bilens värde.
    </li></br>
    <li>Märke och Modell: Vissa bilmodeller har högre efterfrågan på begagnatmarknaden, även om de är trasiga, och detta kan påverka det pris vi erbjuder.
    </li></br>
    <li>Årsmodell: Nyare bilar tenderar att vara mer värdefulla än äldre bilar, även om de är trasiga.
    </li></br>
    <li>Kilometerställning: Bilar med lägre körsträcka är generellt mer värdefulla än de med högre körsträcka, även om de har mekaniska problem.
    </li></br>
    <li>Reservdelsvärde: I vissa fall kan din bil ha ett högt värde på grund av reservdelar som fortfarande är i gott skick och kan användas för andra fordon.
    </li></br>
    </ol>
    Vårt mål är att ge dig ett rättvist pris baserat på dessa faktorer, och vi strävar alltid efter att erbjuda det högsta möjliga värdet för din bil.
    `
  },
  {
    question: 'Vilka typer av betalning erbjuder ni?',
    answer:'Vi erbjuder både banköverföring, kontant betalning eler Swish, beroende på vad som passar dig bäst.'
  },
  {
    question: 'Köper ni alla typer av bilar?',
    answer:'Ja, vi på Tigerbil specialiserar oss på att köpa trasiga, begagnade, defekta bilar i alla skick.'
  },
  {
    question:'Vad gör jag om jag inte har registreringsbeviset för bilen?',
    answer:`Om du inte har registreringsbeviset för bilen kan det fortfarande vara möjligt att sälja den till oss, men processen kan bli något mer komplicerad. Här är några steg du kan ta om du saknar registreringsbeviset:
    <ol></br>
    <li><b>Kontakta Transportstyrelsen:</b> Du kan begära ett nytt registreringsbevis från Transportstyrelsen. Detta kan oftast göras online eller via telefon, och det nya beviset skickas till dig inom några dagar.</li></br>
    <li><b>Diskutera med oss:</b> Kontakta oss och förklara situationen. I vissa fall kan vi fortfarande hjälpa dig att slutföra försäljningen, särskilt om du kan bevisa äganderätten på annat sätt, till exempel genom tidigare registrering eller försäkringsdokument.
    </li></br>
    <li><b>Förbered Alternativa Dokument:</b> Om du har andra dokument som bevisar att du äger bilen, som försäkringspapper, gamla registreringsbevis, eller kvitton från tidigare reparationer, kan dessa också hjälpa till att underlätta försäljningen.
    </li></br>
    </ol>
    Vi strävar alltid efter att göra processen så smidig som möjligt, även om det saknas vissa dokument. Kontakta oss så att vi kan diskutera din specifika situation och hitta en lösning.
    `
  },
  {

    question:'Är det säkert att sälja min bil till Tigerbilar?',
    answer:`Våra kunders säkerhet och tillfredsställelse är vår allra högsta prioritet. Vi följer noggrant alla lagar och regler som rör bilförsäljning, och ser till att hela processen, inklusive registrering och ägarbyte, sker korrekt och lagligt. Som en pålitlig affärspartner har vi en stark kreditvärdighet, vilket våra många nöjda kunder och positiva recensioner vittnar om. Vår approach är alltid vänlig och aldrig påstridig. När avtalet är klart, betalar vi direkt och hämtar därefter ditt fordon.`
  }
]