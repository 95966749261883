import React, { useEffect } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Typography from "../components/Typography";
import FormView from "../components/FormView";
// import { email, required } from "../components/form/validation";
import RFTextField from "../components/form/RFTextField";
import FormButton from "../components/form/FormButton";
import FormFeedback from "../components/form/FormFeedback";
import BasicModal from "../components/Modal";
import { useHistory } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Grid } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";

function ContactUs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [sent, setSent] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalMsg, setModalMsg] = React.useState({});
  const location = useHistory();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    location.push("/");
    setOpen(false);
  };

  const handleSubmit = (values, form) => {
    setSent(!sent)
    const msg = {
      from_name: values.name,
      Namn: values.name,
      Epost: values.email,
      TelefonNummer: values.phoneNumber,
      RegistreringNummer: values.registreringsnummer,
      Kommentar: values.comment,
    };
    emailjs
      .send("service_p27m018", "template_tvshuuh", msg, "ZCWE0V9TyHmUfyAmk")
      .then(
        () => {
          form.reset();
          setModalMsg({
            title: "Bekräftelse",
            description:
              "Tack för att du valde oss, vi återkommer så snart som möjligt! 😀",
          });
          handleOpen();
          setSent(false);
          location.push("?confirmed");
        },
        (error) => {
          setModalMsg({
            title: "Oppsss 😞",
            description: "Något gick fel, försök igen! 😀",
          });
          handleOpen();
          form.reset();
          setSent(false);
        }
      );
  };

  return (
    <Grid
    container
    sx={(theme) => ({
      justifyContent:'space-between',
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
      },
    })}
  >
    <ContactUsSection/>
    <section id="form">
      <FormView>
        <React.Fragment>
          <Typography
            id='kontakta-oss'
            variant="h5"
            component='p'
            sx={(theme) => ({
              color:'var(--color-darknavy)',
              [theme.breakpoints.down("md")]: {
                fontSize: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
              },
              fontWeight:'bold',
              lineHeight: 1.6,
              
            })}
            gutterBottom
            marked="center"
            align="center"
          >
            Gratis Värdering
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          // validate={validate}
        >
          {({ handleSubmit, submitting }) => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 2 }}
              
            >
              <Field
                fullWidth
                size="small"
                component={RFTextField}
                disabled={submitting || sent}
                name="name"
                autoComplete="name"
                label="Namn"
                type="text"
                margin="normal"
              />
              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="E-post"
                margin="normal"
                name="email"
                size="small"
              />
              <Field
                fullWidth
                size="small"
                component={RFTextField}
                disabled={submitting || sent}
                name="phoneNumber"
                // autoComplete="current-password"
                label="Telefonnummer"
                type="tel"
                margin="normal"
              />
              <Field
                autoComplete="plate"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Registreringsnummer"
                margin="normal"
                name="registreringsnummer"
                size="small"
              />
              <Field
                fullWidth
                size="small"
                component={RFTextField}
                disabled={submitting || sent}
                name="comment"
                autoComplete="text"
                label="Kommentar"
                type="text"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent}
                size="small"
              >
                Värdera bilen
              </FormButton>
            </Box>
          )}
        </Form>
      </FormView>
      <BasicModal
        location={location}
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        msg={modalMsg}
      />
    </section>
    </Grid>
  );
}

export default ContactUs;
