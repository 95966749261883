import { ReactGoogleReviews } from "react-google-reviews";
import "react-google-reviews/dist/index.css";
import Review from "./Review";
import { Box } from "@mui/material";

export default function Reviews() {
  // Create a free Featurable account at https://featurable.com
  // Then create a new Featurable widget and copy the widget ID
  const featurableWidgetId = "2c75a8bc-1f68-4814-9aa9-ef625a61223d";
return(
  <ReactGoogleReviews layout="custom" featurableId={featurableWidgetId} renderer={(reviews) => {
    return (
      <Box sx={(theme)=>({
          paddingTop:theme.spacing(8),
        background:"var(--color-lightblue)"
      })}>
        <Review reviews={reviews}/>
      </Box>
    );
  }} />)
}


