import * as React from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PhoneInTalkTwoToneIcon from "@mui/icons-material/PhoneInTalkTwoTone";
import ForwardToInboxTwoToneIcon from "@mui/icons-material/ForwardToInboxTwoTone";
import { Link, ListItemButton } from "@mui/material";

const contactItems = [
  {
    icon: <PhoneInTalkTwoToneIcon />,
    label: "073-992 03 73",
    path: "tel:0739920373",
  },
  {
    icon: <PhoneInTalkTwoToneIcon />,
    label: "08202933",
    path: "tel:08202933",
  },
  {
    icon: <ForwardToInboxTwoToneIcon />,
    label: "info@tigerbilar.se",
    path: "mailto:tigerbilar.se",
  },
];

const contactItem = contactItems.map((item, index) => (
  <ListItemButton component={Link} href={item.path}>
    <ListItem sx={{ pl: 0 }}>
      <ListItemAvatar>
        <Avatar sx={{ backgroundColor: "var(--color-blue)" }}>
          {item.icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText key={index} primary={item.label} />
    </ListItem>
  </ListItemButton>
));

const Demo = styled("div")(() => ({
  backgroundColor: "var(--color-lightblue)",
}));

export default function InteractiveList() {
  return (
    <>
      <Grid item xs={6} sm={8} md={4}>
        <Typography
          pb={2}
          variant="h6"
          textAlign={"left"}
          sx={{ borderBottom: "1px solid #eaeaea;" }}
          color= "var(--color-darknavy)"
        >
          Kontakt
        </Typography>
        <Demo>
          <List>{contactItem}</List>
        </Demo>
      </Grid>
      <Grid item xs={6} sm={8} md={4}>
        <Typography
          pb={2}
          variant="h6"
          textAlign={"left"}
          sx={{ borderBottom: "1px solid #eaeaea;" }}
          color= "var(--color-darknavy)"
        >
          Öppettider
        </Typography>
        <Demo>
          <List>
            <ListItem sx={{ pl: 0 }}>
              <ListItemText
                primary={
                  "Det är bra att höra att vi är tillgängliga 24/7! Vad kan vi hjälpa dig med?"
                }
              />
            </ListItem>
          </List>
        </Demo>
      </Grid>
    </>
  );
}
