import React from "react";

export default function SlagenLeft() {
  return (
    <div className="slagenLeftWrapper">
      <p>sälj</p>
      <p>{`Trasig`}</p>
      <p>{`bil!`}</p>
    </div>
  );
}
