import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";

//img
import headerImg from "../assets/hero.png";
import wave from "../assets/wave.svg";
import DetailsSection from "../components/SaljTrasigbil/DetailsSection";
import HowItWorksSection from "../components/SaljTrasigbil/HowItWorksSection";
import ContactUs from "./ContactUs";
import Faq from "../components/SaljTrasigbil/Faq";
import CallOutSection from "../components/SaljTrasigbil/CallOutSection";
import { Helmet } from "react-helmet-async";

const SaljTrasigBil = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      paddingTop: theme.spacing(3),
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "1",
      textAlign: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.up("md")]: {
      flex: "2",
      textAlign: "left",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <>
      <CustomBox
        sx={{ backgroundColor: "var(--color-lightblue)" }}
        component="header"
      >
        <Helmet>
          <title>Sälja trasig bil</title>
          <link rel="canonical" href="/salj-trasig-bil" />
          <meta
            name="description"
            content="Sälja Trasig Bil Snabbt och Enkelt Få bästa pris för din trasiga bil idag. Vi köper alla bilar oavsett skick och gör processen smidig och bekväm för dig."
          />
          <script type="application/ld+json">
            {`
          {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://tigerbilar.se/salj-trasig-bil",
                "url": "https://tigerbilar.se/salj-trasig-bil",
                "name": "Tigerbilar - Sälj trasig bil enkelt och snabbt",
                "isPartOf": {
                  "@id": "https://tigerbilar.se/#website"
                },
                "about": {
                  "@id": "https://tigerbilar.se/#organization"
                },
                "primaryImageOfPage": {
                  "@id": "https://tigerbilar.se/#primaryimage"
                },
                "image": {
                  "@id": "https://tigerbilar.se/#primaryimage"
                },
                "thumbnailUrl": "https://tigerbilar.se/static/media/hero.f52b6791297b1e41adc5.png",
                "datePublished": "2023-05-29T23:08:34+00:00",
                "dateModified": "2024-07-23T07:14:50+00:00",
                "description": "Sälj din trasiga bil snabbt och enkelt via Tigerbilar. Få ett konkurrenskraftigt erbjudande oavsett bilens skick.",
                "breadcrumb": {
                  "@id": "https://tigerbilar.se/#breadcrumb"
                },
                "inLanguage": "sv-SE",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://tigerbilar.se/salj-trasig-bil"
                    ]
                  }
                ]
              },
              {
                "@type": "ImageObject",
                "inLanguage": "sv-SE",
                "@id": "https://tigerbilar.se/#primaryimage",
                "url": "https://tigerbilar.se/static/media/car1.444fad1c5c7411fb51be.png",
                "contentUrl": "https://tigerbilar.se/static/media/car1.444fad1c5c7411fb51be.png",
                "width": 1344,
                "height": 638,
                "caption": "Bild på trasig bil som säljs via Tigerbilar"
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://tigerbilar.se/#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Sälj trasig bil"
                  }
                ]
              },
              {
                "@type": "WebSite",
                "@id": "https://tigerbilar.se/#website",
                "url": "https://tigerbilar.se/",
                "name": "Tigerbilar",
                "description": "Tigerbilar - Specialiserade på att köpa trasiga och begagnade bilar i Sverige.",
                "publisher": {
                  "@id": "https://tigerbilar.se/#organization"
                },
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://tigerbilar.se/?s={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "sv-SE"
              },
              {
                "@type": "Organization",
                "@id": "https://tigerbilar.se/#organization",
                "name": "Tigerbilar",
                "url": "https://tigerbilar.se/",
                "logo": {
                  "@type": "ImageObject",
                  "inLanguage": "sv-SE",
                  "@id": "https://tigerbilar.se/#/schema/logo/image/",
                  "url": "https://tigerbilar.se/static/media/logo2.f2eaad1ee1732b2a2410.png",
                  "contentUrl": "https://tigerbilar.se/static/media/logo2.f2eaad1ee1732b2a2410.png",
                  "width": 223,
                  "height": 50,
                  "caption": "Tigerbilar"
                },
                "image": {
                  "@id": "https://tigerbilar.se/#/schema/logo/image/"
                },
                "sameAs": [
                  "https://www.facebook.com/tigerbilar",
                  "https://www.instagram.com/tigerbilar"
                ]
              }
            ]
          } 
          `}
          </script>
        </Helmet>
        {/*  Box text  */}
        <BoxText component="section">
          <Typography
            variant="h1"
            sx={(theme) => ({
              fontWeight: 700,
              color: "var(--color-darknavy)",
              [theme.breakpoints.down("md")]: {
                paddingLeft: "10px",
                paddingRight: "10px",
              },
            })}
            component="h1"
          >
            Sälja Trasig Bil Snabbt och Enkelt
          </Typography>

          <Typography
            variant="body1"
            component="p"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
              py: 3,
              lineHeight: 1.6,
              color: "var(--color-darknavy)",
            })}
          >
            Få bästa pris för din trasiga bil idag. Vi köper alla bilar oavsett
            skick och gör processen smidig och bekväm för dig. Låt oss hjälpa
            dig att sälja din trasiga bil utan krångel och få pengarna du
            förtjänar.
          </Typography>

          <Box>
            <Button
              variant="contained"
              href="#kontakta-oss"
              sx={{
                mr: 2,
                px: 4,
                py: 1,
                fontSize: "1.2rem",
                fontWeight: 700,
                textTransform: "capitalize",
                borderColor: "#14192d",
                color: "white",
                borderRadius: "50px",
                backgroundColor: "var(--color-blue)",
                "&&:hover": {
                  backgroundColor: "var(--color-orange)",
                },
              }}
            >
              Sälj Nu!
            </Button>
          </Box>
        </BoxText>

        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              flex: "2",
              paddingTop: "30px",
              alignSelf: "center",
            },
            [theme.breakpoints.up("md")]: {
              flex: "2",
              alignSelf: "flex-start",
            },
          })}
        >
          <img
            src={headerImg}
            style={{
              height: "auto",
              maxWidth: "100%",
            }}
            alt="Illustration of two people exchanging car keys next to an orange car, with a mobile app screen displaying car details and a 'Sell Now' button."
            title="Car Sale Transaction with Mobile App Interface"
          />
        </Box>
      </CustomBox>
      <Box>
        <img
          src={wave}
          style={{
            width: "100%",
          }}
          alt="Abstract wave pattern in a flowing design, symbolizing fluidity and movement."
          title="Fluid Wave Design"
        />
      </Box>
      <DetailsSection />
      <CallOutSection />
      <HowItWorksSection />
      <ContactUs />
      <Faq />
    </>
  );
};

export default SaljTrasigBil;
