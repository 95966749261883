import React, { useEffect } from "react";
import Speedometer from "../components/Speedometer";
import SlagenLeft from "../components/SlagenLeft";
import SlagenRight from "../components/SlagenRight";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Button from "@mui/material/Button";
import Benefits from "./Benefits";
import ContactUs from "./ContactUs";
import Reviews from "../components/reviews/Reviews";

function Home() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="home">
      <div className="bannerTitle">
        <span>Få ditt erbjudande på</span>
        <span className="animatedWords">{` 90 sekunder`}</span>
      </div>
      <div className="bannerContent">
        <SlagenLeft />
        <Speedometer />
        <SlagenRight />
        <Button
          variant="contained"
          component={Link}
          to="/kontakta-oss"
          sx={{
            color: "white",
            fontSize: "24px",
            display: { sm: "block", md: "none" },
            background: "var(--color-blue)",
            textTransform:'none'
          }}
        >
          Värdera Nu
        </Button>
      </div>
      <Benefits />
      <ContactUs />
      <Reviews />
    </div>
  );
}

export default Home;
