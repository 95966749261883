import * as React from "react";
import PhoneInTalkTwoToneIcon from "@mui/icons-material/PhoneInTalkTwoTone";
import ForwardToInboxTwoToneIcon from "@mui/icons-material/ForwardToInboxTwoTone";
import EmojiTransportationRoundedIcon from "@mui/icons-material/EmojiTransportationRounded";
import {
  Box,
  Typography,
  Avatar,
  ListItemText,
  ListItemAvatar,
  ListItem,
  List,
} from "@mui/material";
import { CustomGridItem } from "./SaljTrasigbil/CustomStyles";

const contactItems = [
  {
    icon: <PhoneInTalkTwoToneIcon />,
    label: "073-992 03 73",
    description: "",
  },
  {
    icon: <EmojiTransportationRoundedIcon />,
    label: "Organisationsnummer:",
    description: "5593866063",
  },
  {
    icon: <ForwardToInboxTwoToneIcon />,
    label: "info@tigerbilar.se",
    description: "",
  },
];

const contactItem = contactItems.map((item, index) => (
  <ListItem sx={{ pl: 0 }}>
    <ListItemAvatar>
      <Avatar sx={{ backgroundColor: "var(--color-lightblue)" }}>{item.icon}</Avatar>
    </ListItemAvatar>
    <ListItemText
      key={index}
      primary={item.label}
      secondary={item.description}
      primaryTypographyProps={{
        fontSize: "24px",
        color:'var(--color-darknavy)',
      }}
      secondaryTypographyProps={{
        color:'var(--color-darknavy)',
        fontSize: "24px",
      }}
    />
  </ListItem>
));

const ContactUsSection = () => {
  return (
    <CustomGridItem item xs={18} sm={8} md={6} component="section">
      <Box
        component="article"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
          [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
        })}
      >
        <Typography
          variant="h4"
          component="h3"
          sx={(theme) => ({
            color:'var(--color-darknavy)',
            fontWeight: "700",
            textAlign: "start",
            [theme.breakpoints.down("md")]: {
              textAlign: "center",
            },
          })}
        >
          Fyll i vårt enkla formulär för att få en snabb och noggrann värdering!
        </Typography>
        <List
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: "inline-table",
            },
            [theme.breakpoints.up("md")]: {
              display: "block",
            },
          })}
        >
          {contactItem}
        </List>
      </Box>
    </CustomGridItem>
  );
};

export default ContactUsSection;
